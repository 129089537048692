// Body
$body-bg: #ffffff;
$secondary-bg: #f5f5f5;
$primary: #9d262a;
$bg-primary:#BE3034;
$bg-secondary: #707070;
$bg-third: #FAFAFA;
$bg-gray: #F2F2F2;
$secondary: #878887;
$third:#FFFFFF;
$success: #10BE16;
$gray-primary: #1d1d1d;
$gray-secondary: #707070;
$menu-trans: #ffffff;
$disabled: #666666;
$danger: #ff0000;
$discount-color: #E75046;
$yellow: #FAD000;
$blue: #3783C0;
$red: #E75046;
$text-gray: #484848;
$text-imput: #222222;
$text-gray-light: #707070;
$text-place-holder: #c4c4c4;
$text-white: #ffffff;
$text-black: #272727;
$text-red:#BE3034;
$verification-enabled: #1CA1F2;
$bg-table-secondary: #F0ECEC;

// Typography
$font-family-sans-serif: 'Quicksand', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
.text-white{
    color: #ffffff;
}
.bg-success{
    background-color: $success;
}
.bg-secondary{
    background-color: $secondary;
}
.bg-primary{
    background-color: $primary;
}
.text-gray-primary {
    color: $gray-primary;
}
.text-success {
    color: $success;
}
.text-gray-secondary {
    color: $gray-secondary;
}
