@import './variables';
.crud-input-container{
    position: relative;
    input{
        &:focus{
            outline: none;
        }
    }
    .crud-input-selected-container{
        margin: 0;
        padding: 0 0 3px 0;
        display: block;
        width: 100%;
        border-bottom: 1px solid $text-gray;
        .crud-current-selected-input{
            border: none;
            width: 100%;
            padding: 0 0 1px 3px;
            cursor:pointer;
            background-color: transparent;
        }
    }
    .crud-list{
        margin: 0;
        padding: 0;
        display: none;
        list-style: none;
        position: absolute;
        width: 100%;
        left: 0;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        z-index: 10;
        transform: translateY(-1px);
        max-height: 25vh;
        .crud-item-add{
            margin: 0;
            padding: 0 0 0 3px;
            background-color: rgba(190, 48, 52, 0.15);
            position: sticky;
            top: 0;
            left: 0;
            input{
                background-color: transparent;
                border: none;
                border-bottom: 1px solid $text-gray;
                margin: 5px 0;
                width: 80%;
            }
            i{
                background-color: $bg-primary;
                color: $text-white;
                padding: 10px;
                font-size: 10px;
            }
        }
        .crud-item-update{
            margin: 0;
            padding: 2px 0 2px 3px;
            cursor: pointer;
            transition: .3s;
            display: flex;
            input{
                background-color: transparent;
                border: none;
                margin: 2px 0;
                width: 100%;
                cursor: pointer;
            }
            i{
                padding: 10px 8px;
                font-size: 12px;
            }
            &:hover{
                background-color: #F0F0F0;
                
            }
        }
    }
}